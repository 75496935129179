import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CardMedia } from "@material-ui/core";
import "./App.css";
import AzureAD from "react-aad-msal";
import { authProvider, getToken } from "./authProvider";

const useStyles = makeStyles(theme => ({
  heading_container: {
    color: "#FFF",
    alignSelf: "stretch",
    padding: "25px"
  },
  heading: {
    paddingBottom: "3px",
    textAlign: "center",
    color: "#FFF",
    fontFamily: "Domaine Display",
    fontSize: "32px",
    display: "block!important"
  },
  month_heading: {
    textAlign: "center",
    fontFamily: "Domaine Display",
    fontSize: "20px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const AzureLogin = () => {
  const classes = useStyles({});
  const [token, setToken] = React.useState("");

  const [load, setLoad] = React.useState("");

  React.useEffect(() => {
    const getT = async () => {
      return await getToken();
    };

    if (authProvider.authenticationState === "Authenticated") {
      getT().then(b => {
        setToken(b.idToken.rawIdToken);
      });
    }

    setLoad(true);
  }, []);

  if (!load) return null;

  return (
    <Box>
      <CardMedia
        className={classes.heading_container}
        image="https://hh-media-test.fra1.digitaloceanspaces.com/widget/bg.jpg"
      >
        <Box className={classes.heading}>Topplistor test</Box>
      </CardMedia>
      <AzureAD provider={authProvider} forceLogin={true}>
        <div id="toplist-widget" token={token}></div>
        <div id="toplist-widget-boost" token={token}></div>
      </AzureAD>
    </Box>
  );
};

function App() {
  return <AzureLogin />;
}

export default App;
