import { MsalAuthProvider, LoginType } from "react-aad-msal";

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority:
      `${process.env.REACT_APP_ISSUER_URL}`, //Directory (tenant) ID Overview blade of App Registration
      clientId: `${process.env.REACT_APP_CLIENT_ID}` //Application (client) ID
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    },
    navigateToLoginRequestUrl: false
  },
  {
    scopes: ["https://graph.microsoft.com/.default"]
  },
  {
    loginType: LoginType.Redirect
  }
);

export const getToken = async () => {
  return await authProvider.getIdToken();
}

export const authFetch = async (url) => {
  const token1 = await authProvider.getIdToken();

  console.log("token", token1.idToken.rawIdToken);

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token1.idToken.rawIdToken,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .catch(error => console.log(error));
};